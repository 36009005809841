/* General Styles */
body {
  font-family: 'Roboto', 'Arial', sans-serif;
  background-color: #121212; /* Dark background */
  margin: 0;
  padding: 0;
  color: #E0E0E0; /* Lighter text for readability */
}

.App {
  text-align: center;
  background-color: #1E1E1E; /* Slightly lighter than body for contrast */
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); /* Adjust shadow for dark mode */
  width: 100%;
}

/* Container Styles */
.container {
  background-color: #1E1E1E;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

/* Typography Styles */
.typography {
  color: #E0E0E0; /* Lighter text for readability */
}

/* Text Field Styles */
.textField {
  background-color: #2C2C2C; /* Dark background for text fields */
  color: #E0E0E0; /* Light text */
  border: 1px solid #333; /* Border color for text fields */
}

/* Labels inside text fields */
.textField label {
  color: #7f7f7f; /* Light text for labels */
}

/* Input inside text fields */
.textField input {
  color: #E0E0E0; /* Light text for input */
}

.textField .MuiInputBase-input {
    color: #E0E0E0; /* Replace #yourColor with the color you want for the text */
}

/* Button Styles */
.button {
  background-color: #4FC3F7; /* Stylish blue for buttons */
  color: #121212; /* Dark text for contrast */
  border: none;
}

/* Button Hover Effect */
.button:hover {
  background-color: #35B0F6; /* Slightly lighter blue on hover */
}

/* Room Container Styles */
div.room-container {
  display: flex;
  width: 100%;
  height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;
}

.fullMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
}

.fullMessage .textField {
  width: 100%; /* Full width within the container */
  max-width: 400px; /* Maximum width of the text field */
  margin: 0 auto; /* Centers the text field within its container */
}

/* Chat Section Styles */
.chat-section {
  flex-grow: 20; /* Takes up majority of space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 20px;
}

.message-area {
  flex-grow: 0;
  overflow-y: auto;
  margin-bottom: 10px;
  height: 100%;
  background-color: #2C2C2C; /* Dark background for message area */
  color: #E0E0E0; /* Lighter text for messages */
  border: 1px solid #4FC3F7; /* Light blue border for contrast */
  padding: 10px; /* Optional padding for inner spacing */
  border-radius: 5px; /* Optional for rounded corners */
}

/* Sidebar Section Styles */
.sidebar-section {
  flex-grow: 1;
  padding-left: 20px;
  border-left: 1px solid #ddd;
  /* other styles */
}

.campaign-box {
  display: flex;
  flex-direction: column;
  align-items: center; /* Optional, for center alignment of children */
}

.room-details-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Optional, for center alignment of children */
}

.room-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Optional, for center alignment of children */
}

.connected-users {
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Optional, for center alignment of children */
}

.copyToClipboard{
  width:20px;
  height:20px;
}

.MuiButton-root {
  color: #E0E0E0; /* For text */
  background-color: #4FC3F7; /* For background */
}

.MuiButton-root:hover {
  background-color: #35B0F6; /* Lighter on hover */
}

.MuiButton-root.Mui-disabled {
  background-color: #37474F; /* Darker when disabled */
  color: #B0BEC5; /* Lighter text for disabled */
}

.MuiTextField-root {
  background-color: #2C2C2C; /* Dark background */
  color: #E0E0E0; /* Light text */
}

.MuiInputBase-root {
  color: #E0E0E0; /* Light text */
}

.MuiInputLabel-root {
  color: #E0E0E0; /* Light text for labels */
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: #333; /* Border color */
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4FC3F7; /* Border color when focused */
}

div.MuiDialog-paper {
  background-color: #1E1E1E; /* Dialog background */
  color: #E0E0E0; /* Text color */
}

.MuiList-root {
  /*background-color: #262626; /* Background for lists */
  color: #E0E0E0; /* Text color for lists */
}

.MuiTypography-root {
  color: #E0E0E0; /* Text color for typography */
}

p.MuiTypography-root {
  color: #E0E0E0; /* Text color for typography */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .room-container {
    flex-direction: column;
  }
  .sidebar-section {
    border-left: none;
    border-top: 1px solid #ddd;
    padding-top: 20px;
  }
}
